<script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import { overlay } from "@/state/helpers";
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import EmptyTableResult from "@/components/empty-table-result";
  import appConfig from "@/../app.config";
  import { callUrl } from '@/helpers/api-apolo';
  import * as helpers from '@/helpers/helper-functions';
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  export default {
    page: {
      title: "Logs de Integração",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Logs de Integração",
        items: [{
            text: "CRM",
            href: "/"
          },
          {
            text: "Integrações",
            href: "/hooks"
          },
          {
            text: "Logs de Integração",
            active: true
          },
        ],
        configDateTime: {
            wrap: true,
            allowInput: true,
            enableTime: true,
            dateFormat: "Y-m-dTH:i:S",
            altInput: true,
            altFormat: "d/m/Y H:i"
        },
        entityNames: {
            "Account": "Conta",
            "Pipeline": "Pipeline",
            "Business": "Negócio",
            "Person": "Pessoa",
            "Product": "Produto",
            "Task": "Tarefa",
            "User": "Usuário"
        },
        actionNames: {
            "Create": "Criação",
            "Update": "Atualização",
            "Delete": "Remoção"
        },
        authenticationTypeNames: {
          'None': 'Nenhuma',
          'Basic': 'Basic Authentication',
          'ApiKey': 'ApiKey Token'
        },
        filters: {
          query: '',
          pipeline_id: '',
          hook_id: '',
          entity: '',
          action: '',
          status_code: '',
          date_begin: '',
          date_end: ''
        },
        page: 1,
        perPage: 10,
        pipelines: [],
        hooks: [],
        dataList: [],
        activeHook: {
            hook_code: '',
            entity: '',
            action: '',
            pipeline_id: '',
            url: '',
            authentication_type: '',
            authentication_pass: '',
            status_code: '',
            message: '',
            request_sent: {
                header: '',
                body: '',
            },
            request_received: {
                header: '',
                body: '',
            }
        }
      }
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
      EmptyTableResult,
      flatPickr
    },
    computed: {
      pages() {
        if (this.page == 1 && !this.dataList.length) {
          return [];
        }
        if (this.page == 1 && this.dataList.length < this.perPage) {
          return [1];
        }
        if (this.page == 1 && this.dataList.length == this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.dataList.length || this.dataList.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.fetchData();
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      translateEntity(value) {
        return this.entityNames[value]||value;
      },
      translateAction(value) {
        return this.actionNames[value]||value;
      },
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchData();
        }
      },
      async fetchData() {
        try {
          this.changeShowOverlay({show: true});
          this.dataList = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`, `order=created_at:1`];
          if (this.filters.query.length > 2) {
            params.push(`query=${this.filters.query}`);
          }
          if (this.filters.entity?.length > 0) {
            params.push(`entity=${this.filters.entity}`);
          }
          if (this.filters.action?.length > 0) {
            params.push(`action=${this.filters.action}`);
          }
          if (this.filters.pipeline_id?.length > 0) {
            params.push(`pipeline_id=${this.filters.pipeline_id}`);
          }
          if (this.filters.status_code?.length > 0) {
            params.push(`status_code=${this.filters.status_code}`);
          }
          if (this.filters.hook_id?.length > 0) {
            params.push(`hook_id=${this.filters.hook_id}`);
          }
          if (this.filters.date_begin?.length > 0) {
            params.push(`date_begin=${this.filters.date_begin}`);
          }
          if (this.filters.date_end?.length > 0) {
            params.push(`date_end=${this.filters.date_end}`);
          }
          const dataM = await callUrl({}, `/hook-executed?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.dataList = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      async getAllPipelines() {
        try {
          this.pipelines = [];
          const dataM = await callUrl({}, '/pipeline', 'get')
          if (dataM && dataM.length) {
            this.pipelines = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      async getAllHooks() {
        try {
          this.hooks = [];
          const dataM = await callUrl({}, '/hook', 'get')
          if (dataM && dataM.length) {
            this.hooks = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      viewItem(item) {
        this.activeHook = item
      }
    },
    mounted() {
        this.getAllPipelines();
        this.getAllHooks();
        this.fetchData();
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="customFieldList">

          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Logs de Integração</h5>
              <div class="flex-shrink-0">
              </div>
            </div>
          </div>

          <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
            <form>
              <div class="row g-3">
                <div class="col-sm-6 col-md-4">
                  <div class="search-box">
                    <input type="text" class="form-control search bg-light border-light" v-model="filters.query" placeholder="Busca por algum termo...">
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="search-box">
                    <input type="text" class="form-control search bg-light border-light" v-model="filters.status_code" placeholder="status code recebido">
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.entity" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todas entidades' }].concat(Object.keys(entityNames).map(key => ({value:key, label:entityNames[key]})))" />
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.action" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todas ações' }].concat(Object.keys(actionNames).map(key => ({value:key, label:actionNames[key]})))" />
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.hook_id" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todas integrações' }].concat(hooks.map(hook => ({value:hook.id, label:hook.code})))" />
                  </div>
                </div>

                <div class="col-sm-6 col-md-2">
                  <div class="input-light">
                    <Multiselect class="form-control" v-model="filters.pipeline_id" :close-on-select="true" :searchable="true"
                      :create-option="true"
                      :options="[{ value: '', label: 'Todos pipelines' }].concat(pipelines.map(pipeline => ({value:pipeline.id, label:pipeline.name})))" />
                  </div>
                </div>
                
                <div class="col-sm-6 col-md-2">
                    <flat-pickr
                        v-model="filters.date_begin"
                        :config="configDateTime"
                        :placeholder="'Data de início'"
                        class="form-control flatpickr-input" />
                </div>

                <div class="col-sm-6 col-md-2">
                    <flat-pickr
                        v-model="filters.date_end"
                        :config="configDateTime"
                        :placeholder="'Data de fim'"
                        class="form-control flatpickr-input" />
                </div>

                <div class="col-sm-6 col-md-2">
                  <button type="button" class="btn btn-primary w-100" @click="search()">
                    <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table align-middle table-nowrap">
                  <thead class="text-muted">
                    <tr>
                      <th class="sort text-uppercase">Integração</th>
                      <th class="sort text-uppercase">Pipeline</th>
                      <th class="sort text-uppercase">Entidade</th>
                      <th class="sort text-uppercase">Ação</th>
                      <th class="sort text-uppercase">Status Code</th>
                      <th class="sort text-uppercase">Realizado em</th>
                      <th class="sort text-uppercase">Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of dataList" :key="index">
                      <td>{{item.hook_code}}</td>
                      <td><router-link :to="{ name: 'crm-deals' }">{{item.pipeline_id}}</router-link></td>
                      <td>{{translateEntity(item.entity)}}</td>
                      <td>{{translateAction(item.action)}}</td>
                      <td><span :class="['badge', {'badge-soft-success': item.status_code >= 200 && item.status_code < 300}, {'badge-soft-danger': item.status_code < 200 || item.status_code >= 300}]">{{item.status_code}}</span></td>
                      <td>{{translateDateOnly(item.created_at)}} <small class="text-muted">{{translateTimeOnly(item.created_at)}}</small></td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item" data-bs-toggle="modal" href="#showmodal" @click.prevent="viewItem(item)" style="cursor:pointer">
                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Visualizar
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <EmptyTableResult v-show="dataList.length == 0" />

              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                  <a class="page-item pagination-prev disabled" href="#" v-if="dataList.length && page == 1" @click.prevent="">Anterior</a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                      :class="{active: pageNumber == page, disabled: pageNumber != page}">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="++page" v-if="dataList.length == perPage">Próxima</a>
                  <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="dataList.length && dataList.length < perPage">Próxima</a>
                </div>
              </div>

                <!-- Modal -->
                <div class="modal fade zoomIn" id="showmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content border-0">
                            <div class="modal-header p-3 bg-soft-info">
                                <h5 class="modal-title" id="exampleModalLabel">Log da Integração {{activeHook.hook_code}}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                            </div>
                            <form id="addform">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label for="hookEntity" class="form-label">Entidade</label>
                                            <Multiselect class="fieldDisabled" :disabled="true" id="hookEntity" v-model="activeHook.entity" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[].concat(Object.keys(entityNames).map(key => ({value:key,label:entityNames[key]})))" />
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="hookAction" class="form-label">Ação</label>
                                            <Multiselect class="fieldDisabled" :disabled="true" id="hookAction" v-model="activeHook.action" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[].concat(Object.keys(actionNames).map(key => ({value:key,label:actionNames[key]})))" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <label for="hookIntegration" class="form-label">Integração</label>
                                            <Multiselect class="fieldDisabled" :disabled="true" id="hookIntegration" v-model="activeHook.hook_code" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[{value:activeHook.hook_code,label:activeHook.hook_code}]" />
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="hookPipeline" class="form-label">Pipeline</label>
                                            <Multiselect class="fieldDisabled" :disabled="true" id="hookPipeline" v-model="activeHook.pipeline_id" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[].concat(pipelines.map(pipeline => ({value:pipeline.id,label:pipeline.name})))" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-10">
                                            <label for="hookUrl" class="form-label">Url</label>
                                            <input :disabled="true" type="text" v-model="activeHook.url" id="hookUrl" class="fieldDisabled form-control" />
                                        </div>
                                        <div class="col-lg-2">
                                            <label for="hookStatusCode" class="form-label">Status Code</label>
                                            <input :disabled="true" type="text" v-model="activeHook.status_code" id="hookStatusCode" class="fieldDisabled form-control" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <label for="hookAuthenticationType" class="form-label">Tipo de Autenticação</label>
                                            <Multiselect class="fieldDisabled" :disabled="true" id="hookPipeline" v-model="activeHook.authentication_type" :close-on-select="true" :searchable="true" :create-option="false"
                                                :options="[].concat(Object.keys(authenticationTypeNames).map(key => ({value:key,label:authenticationTypeNames[key]})))" />
                                        </div>
                                        <div class="col-lg-6">
                                            <label for="hookAuthenticationPass" class="form-label">Senha da Autenticação</label>
                                            <input :disabled="true" type="text" v-model="activeHook.authentication_pass" id="hookAuthenticationPass" class="fieldDisabled form-control" placeholder="Senha da autenticação" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <label for="hookMessage" class="form-label">Mensagem</label>
                                            <input :disabled="true" type="text" v-model="activeHook.message" id="hookMessage" class="fieldDisabled form-control" />
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <label for="hookRequestSentHeader" class="form-label">Headers enviado</label>
                                            <textarea :disabled="true" id="hookRequestSentHeader" v-model="activeHook.request_sent.header" class="fieldDisabled form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <label for="hookRequestSentBody" class="form-label">Body enviado</label>
                                            <textarea :disabled="true" id="hookRequestSentBody" v-model="activeHook.request_sent.body" class="fieldDisabled form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <label for="hookRequestReceivedHeader" class="form-label">Headers recebido</label>
                                            <textarea :disabled="true" id="hookRequestReceivedHeader" v-model="activeHook.request_received.header" class="fieldDisabled form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <label for="hookRequestReceivedBody" class="form-label">Body recebido</label>
                                            <textarea :disabled="true" id="hookRequestReceivedBody" v-model="activeHook.request_received.body" class="fieldDisabled form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">Fechar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--end modal-->
            </div>

          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>